import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - मंत्र प्रभाव १" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='मंत्र प्रभाव १'/>
        <div id="main">
          <section id="content" className="main">
            <p>
गण गण गणात बोते । हा मंत्र प्रिय गजाननाते ।< br />
ह्यातले सामर्थ्य इतुके । व्यवहारातले बोल फिके ।< br />
नामजपावर जे घडे, मिळे । ते अश्रद्धावानास न मिळे ।< br />
श्रद्धावान भोगे प्रारब्ध सहजतेने । अश्रद्धावान चिंतेने ।< br />
जो असतो ह्या मंत्र जपावरी । तो न गुरफटणार व्यवहारी ।< br />
ऐसी ज्याची होते स्थिती । चिंतेची न राहे भिती ।< br />
हा मंत्र जपण्यात बने निर्भय । संकटाचा होतो पराजय ।< br />
शुद्ध हेतू पूर्ण होई । चिंता, क्लेश, दुःख न राही ।< br />
ह मंत्र जपावा नित्य नेमाने । गणामहाराज सांगे अनुभवाने ।< br />
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
